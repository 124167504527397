import React from 'react'
import PageNotFound from '../Components/PageNotFound';
import { Helmet } from 'react-helmet';
export default function PageNotFoundx() {
    return (
        <div>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <PageNotFound/>
        </div>
    )
}
